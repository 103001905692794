define('carnival-frontend/routes/messages/edit', ['exports', 'carnival-frontend/routes/messages/new'], function (exports, _new) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _new.default.extend({
    controllerName: 'messages.new',

    model: function model(params) {
      return this.store.findRecord('message', params.message_id);
    },
    renderTemplate: function renderTemplate() {
      this.render('messages.new');
    },
    setupController: function setupController(controller, model) {
      // call after setting up payload/custom
      // otherwise change handler will fire
      this._super(controller, model);
    }
  });
});