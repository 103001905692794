define('carnival-frontend/components/messages/audience-picker', ['exports', 'carnival-frontend/components/popup-revealer', 'carnival-frontend/mixins/fake-pagination', 'carnival-frontend/utils/audience-filter'], function (exports, _popupRevealer, _fakePagination, _audienceFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _popupRevealer.default.extend(_fakePagination.default, {
    app: null,
    selectedTarget: null,
    searchText: '',
    showAudiences: Ember.computed.and('app.audiences.isFulfilled', 'app.permittedAudience.isFulfilled'),
    targets: [],
    dataKey: 'targets',
    per: 5,

    didRender: function didRender() {
      if (this.get('isOpen')) this.$('#audience-search').not(':focus').focus();
    },
    sumAudiences: function sumAudiences(audiences) {
      return audiences.reduce(function (prev, item) {
        return prev + (item.get('childAudiences.length') || 0) + 1;
      }, 0);
    },


    numAudiences: Ember.computed('filteredTargets', 'datalist.length', 'searchText', function () {
      var targets = this.get('searchText') ? this.get('filteredTargets') : this.get('datalist');

      return this.sumAudiences(targets);
    }),

    totalNumAudiences: Ember.computed('targets.length', function () {
      return this.sumAudiences(this.get('targets'));
    }),

    selectedText: Ember.computed('selectedTarget.isFulfilled', function () {
      if (this.get('selectedTarget.content.constructor.modelName') === 'app') return 'Send to Everyone';
      return this.get('selectedTarget.name');
    }),

    targetsDidChange: Ember.on('init',
    // eslint-disable-next-line ember/no-observers
    Ember.observer('app.audiences.isFulfilled', 'app.permittedAudience.isFulfilled', function () {
      var app = this.get('app');
      // eslint-disable-next-line ember/no-observers

      var targets = app.audiences.filter(function (audience) {
        return (0, _audienceFilter.audienceFilter)(audience);
      }).toArray();

      if (!app.get('permittedAudience.content')) targets.unshiftObject(app);

      this.set('targets', targets);
      this.setupPagination();
    })),

    filteredTargets: Ember.computed('targets', 'searchText', function () {
      var targets = this.get('targets'),
          search = this.get('searchText').trim().toLowerCase();

      if (search === '') return targets;

      var matcher = function matcher(t) {
        return t.get('name').toLowerCase().indexOf(search) >= 0;
      };

      return targets.filter(function (t) {
        return matcher(t) || t.get('childAudiences') && t.get('childAudiences').any(matcher);
      });
    })
  });
});