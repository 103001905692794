define('carnival-frontend/controllers/messages/new', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['originalMessage'],
    state: 'authoring',
    originalMessage: null,

    isAuthoring: Ember.computed.equal('state', 'authoring'),
    isPreviewing: Ember.computed.equal('state', 'previewing'),

    uneditable: Ember.computed('messageable.state', function () {
      return this.get('messageable.state') !== 'scheduled' && this.get('messageable.state') !== 'draft';
    }),

    editable: Ember.computed.not('uneditable'),

    disablePublishNow: Ember.computed('model.{publishAt,app.settings.disablePublishNow}', function () {
      return !this.get('model.publishAt') && this.get('model.app.settings.disablePublishNow');
    }),

    disableSave: Ember.computed.or('model.isSaving', 'model.isProcessing', 'disablePublishNow'),
    disableSaveDraft: Ember.computed.or('model.isSaving', 'model.isValidating'),
    disableExpiryDate: Ember.computed.or('model.isPushOnly', 'uneditable'),

    isAbTest: Ember.computed.equal('model.constructor.modelName', 'ab-test'),

    linkToAbTest: Ember.computed.not('automated'),

    sendCopy: Ember.computed('messageable.publishAt', 'model.isDraft', 'updateCopy', 'scheduleCopy', 'publishCopy', function () {
      if (!this.get('model.isDraft')) {
        return this.get('updateCopy');
      } else if (this.get('messageable.publishAt')) {
        return this.get('scheduleCopy');
      } else {
        return this.get('publishCopy');
      }
    }),

    updateCopy: Ember.computed('model.isSaving', function () {
      return this.get('model.isSaving') ? 'Updating...' : 'Update';
    }),

    scheduleCopy: Ember.computed('model.isSaving', function () {
      return this.get('model.isSaving') ? 'Scheduling...' : 'Schedule';
    }),

    publishCopy: Ember.computed('model.isSaving', function () {
      return this.get('model.isSaving') ? 'Publishing...' : 'Publish Now';
    }),

    messageable: Ember.computed('model.firstMessage', function () {
      if (this.get('model.firstMessage')) return this.get('model.firstMessage');
      return this.get('model');
    }),

    displayTestMessageButton: Ember.computed.not('isAbTest'),

    setTemporaryIdIfNewMessage: function setTemporaryIdIfNewMessage(message) {
      var isNewMessage = Ember.isEmpty(message.get('id'));
      if (isNewMessage) {
        message.set('id', 'new'); //new messages need a temp id for the id segment in the route
      }
    },
    validateModel: function validateModel(model) {
      var _this = this;

      model.validate().then(function (response) {
        if (response) model.set('html', response.html);
        model.get('errors').clear();
        _this.set('state', 'previewing');
        _this.setTemporaryIdIfNewMessage(model);
        _this.transitionToRoute('device-preview', model);
      }, function (error) {
        _this.handleError(error);
      });
    },
    saveModel: function saveModel(model) {
      var _this2 = this;

      model.save().then(function () {
        _this2.send('saveSuccess', model);
      }, function (error) {
        _this2.handleError(error);
      });
    },
    saveDraftModel: function saveDraftModel(model, options) {
      var _this3 = this;

      model.validate().then(function () {
        return model.save(options);
      }).then(function () {
        _this3.send('saveSuccess', model);
      }).catch(function (error) {
        _this3.handleError(error);
      });
    },
    handleError: function handleError(error) {
      // if it's not a validation error let the error bubble
      // and get handled at the `application` route level
      if (!(error instanceof _emberData.default.InvalidError)) throw error;
    },


    actions: {
      next: function next(model) {
        switch (this.get('state')) {
          case 'authoring':
            this.validateModel(model);
            break;
          case 'previewing':
            this.saveModel(model);
            break;
        }
      },
      prev: function prev() {
        switch (this.get('state')) {
          case 'authoring':
            this.transitionToRoute('messages');
            break;
          case 'previewing':
            this.set('state', 'authoring');
            break;
        }
      },
      saveDraft: function saveDraft(model) {
        this.saveDraftModel(model, { adapterOptions: { draft: true } });
      }
    }
  });
});