define('carnival-frontend/routes/device-preview', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _route.default.extend({
    messageBuilder: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      var message = model;
      var isNewMessage = message.get('id') === 'new';

      if (isNewMessage) {
        message.set('id', null); //remove temporary id now the route has loaded
      }

      // call after setting up payload/custom
      // otherwise change handler will fire
      this._super(controller, message);
    },
    renderTemplate: function renderTemplate() {
      this.render('device-preview', {
        into: 'application',
        outlet: 'main'
      });
    },
    messageQueryParams: function messageQueryParams(message) {
      var queryParams = {};

      if (!message.get('isInapp')) {
        queryParams.push = true;
      }
      if (message.isDraft) {
        queryParams.state = 'draft';
      }
      if (message.isScheduled) {
        queryParams.state = 'scheduled';
      }
      if (message.isUnpublished) {
        queryParams.state = 'unpublished';
      }
      if (message.isPublished) {
        queryParams.state = 'published';
      }
      return { queryParams: queryParams };
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName === 'messages.index') {
          //happy path
          return;
        }

        this.transitionTo(transition.targetName, this.messageQueryParams(this.modelFor('device-preview')));
      },
      saveSuccess: function saveSuccess() {
        var qps = this.messageQueryParams(this.modelFor('device-preview'));

        var queryParams = _extends({}, qps.queryParams);
        this.transitionTo('messages', { queryParams: queryParams });
      }
    }
  });
});