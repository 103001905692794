define('carnival-frontend/routes/messages/ab-tests/new', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    messageBuilder: Ember.inject.service(),

    model: function model(params) {
      var message = this.get('messageBuilder').originalMessage(params.originalMessage);

      return this.get('messageBuilder').newAbTest({ app: this.modelFor('app') }, message);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var params = this.paramsFor(this.routeName),
          variations = model.get('messages').mapBy('variationName');

      if (variations.indexOf(params.activeVariationName) === -1) {
        controller.set('activeVariationName', variations[variations.length - 1]);
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          state: 'authoring'
        });
      }
    },


    actions: {
      saveSuccess: function saveSuccess(model) {
        this.transitionTo('messages', {
          queryParams: {
            state: model.get('firstMessage.state'),
            triggered: false,
            push: !model.get('messages').isAny('isInapp', true)
          }
        });
        this.send('refresh');
      },
      willTransition: function willTransition(transition) {
        var model = this.controller.get('model');

        this.get('messageBuilder').rollbackAbTest(model, transition.targetName === 'messages.new');
      }
    }
  });
});